<template>
    <st-section :header="title">
        <template #body>
            <div v-for="(value, key, index) in intersections" :key="index">
                <div v-if="value">
                    {{ key }}: {{ value }}
                </div>
            </div>
        </template>
    </st-section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: "GisIntersections",
    props: {
        viewMode: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String
        }
    },
    data() {
        return {
            intersections: [],
        }
    },
    computed: {
        ...mapGetters({
            currentUser: "auth/currentUser",
            record: "applications/form/record"
        })
    },
    methods: {
        ...mapActions({}),
    },
    created() {
        if (this.record) {
            this.intersections = this.record?.form_staff?.gis_intersections ?? [];
        }
    }
};
</script>
